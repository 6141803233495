@import '/src/App/Global/Style/_imports.scss';

$breakpoint-short: 769px;
$breakpoint-medium: 991px;
$breakpoint-large: 1024px;
$breakpoint-extra-large: 1250px;
$breakpoint-g-large: 1920px;

$container-min-large: 1250px;
$container-max-large: 1320px;
$container-max-extra-large: 1920px;


.footer-main {
  background-color: #383838;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  @media (max-width: $breakpoint-large) {
    flex-direction: column;
    align-items: center;
  }
}

.footer-container {
  width: 100%;
  padding: 0 $zr-spacing-vertical-xs;


  @media (min-width: $breakpoint-extra-large) {
    max-width: $breakpoint-extra-large;
  }

  @media (min-width: $breakpoint-large) {
    width: 100%;
  }
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  

  @media (max-width: $breakpoint-extra-large) {
    min-width: 100%;
  }

  @media (min-width: $breakpoint-extra-large) {
    padding: 0 $zr-spacing-vertical-xs;
  }
}

.wrapper-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;

  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}

.content-div {
  display: flex;
  flex-direction: column;
  justify-content: center;  

  @media (max-width: $breakpoint-medium) {
    align-items: flex-start;
  }

  &.program,
  &.contact {
    width: 13%;    

    @media (max-width: $breakpoint-medium) {
      width: 100%;
      padding: $zr-spacing-horizontal-xxxs 0;
    }
  }

  &.social-media {
    width: 18%;

    @media (max-width: $breakpoint-medium) {
      width: 100%;
      padding: $zr-spacing-horizontal-xxxs 0;
    }
  }

  &.telephone {
    justify-content: flex-end;
    width: 55%;
    padding-left: $zr-spacing-vertical-xs;


    @media (max-width: $breakpoint-medium) {
      padding-left: 0;
      width: 100%;
    }
  }

  .title {
    margin: 0;
    color: #8e8e8e;
    font-size: $zr-font-size-micro;
    line-height: 3rem;
    font-family: $ff-light;
  }

  &.social-media {
    .link-list-ul {
      li {
        padding-right: $zr-spacing-vertical-xs;
      }
    }
  }

  .link-list-ul {
    margin: 0;
    list-style: none;
    font-size: $zr-spacing-vertical-xxxs;
    padding: 0;
    line-height: $zr-line-height-medium;

    @media (max-width: $breakpoint-medium) {
      display: flex;

      li {
        padding-right: $zr-spacing-vertical-xs;
      }
    }

    &.telephone {
      display: flex;

      li:first-child {
        width: 35%;
      }

      li:nth-child(2) {
        width: 30%;
      }

      li:nth-child(3) {
        padding-left: $zr-spacing-vertical-xs;
      }

      @media (max-width: $breakpoint-medium) {
        flex-direction: column;

        li {
          padding: $zr-spacing-horizontal-xxxs 0;

          &:first-child,
          &:nth-child(2) {
            width: 100%;
          }

          &:nth-child(3) {
            padding-left: 0;
          }
        }
      }
    }

    a,
    span {
      color: $zr-color-neutral-lightest;
      font-family: $ff-light;
      font-size: $zr-font-size-xxxs;
    }

    a {
      text-decoration: none;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.divider-hr {
  width: 95%;
  border: 2px solid #000;
  opacity: 0.15;
  margin: $zr-spacing-vertical-micro;
}

.text-litle {
  color: $zr-color-neutral-lightest;
  font-family: $ff-light;
  margin: 0;
  font-size: $zr-font-size-micro
}

.content-div-logo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: $zr-spacing-horizontal-sm 0;

  @media (max-width: $breakpoint-medium) {
    flex-direction: column;
    align-items: center;
  }

  .copy-right {
    width: 40%;
    line-height: $zr-line-height-medium;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media (max-width: $breakpoint-medium) {
      width: 100%;
      align-items: center;
    }

    .copy-right-text {
      color: $zr-color-neutral-lightest;
      font-family: $ff-light;
      font-size: $zr-font-size-xxxs;
      margin: 0;
    }
  }
}

.social-media-list {
  flex-wrap: wrap;
  display: flex;
}