@import '/src/App/Global/Style/_imports.scss';

@include mobile {
  .sectionWarning {
    img {
      margin: auto;
      width: 102px;
      height: 93px;
    }

   .warningImportant {
      font-size: $zr-font-size-micro;
    }

   .descriptionWarning {
      font-size: $zr-font-size-sm;
   }

  }

    .buttonUpdateAddressNow {
      width: 330px;
      height: 35px;

      font-size: 14px;
  }
}

.headerStyle {
  display: flex;
  justify-content: center;
  height: 35px;
}

.modalWarning {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sectionWarning {
  display: flex;
  gap: 1rem;
}

.sectionWarning h2 {
  color: $dark-green1;
  margin-top: $zr-spacing-vertical-xs;
  font-family: $ff-bold;
}

.warningImportant {
  font-size: 24px;
  font-family: $ff-bold;
}

.descriptionWarning {
  font-family: $ff-bold;
  font-size: 32px;
  padding-bottom: 16px;
}

.warningAddress {
  margin-top: $zr-spacing-vertical-xxs;
  display: flex;
  flex-direction: column;
  gap: $zr-font-size-md;
}

.helloShopkeeper {
  font-size: 20px;
  color: $dark-green1;  
}

.descriptionUpdateAddress {
  color: $dark-green1;
  line-height: 1.8rem;
}

.descriptionManagerFreight {
 font-size: $zr-font-size-xs;
}

.buttonUpdateAddressNow {
  width: 365px;
  align-self: center;
  height: $zr-font-size-xl;
  font-size: $zr-font-size-xs;
  font-family: $ff-light;
  color: $light-gray2;
  background-color: $dark-blue1;
  border-radius: 5px;

  @include mobile {
  width: 285px;
  height: 45px;
  font-size: $zr-font-size-xxxs;
  }
}

.buttonUpdateAddressNow:hover {
   opacity: 0.8;
   cursor: pointer;
}

.descriptionFooterThanks {
  font-size: $zr-font-size-xxxs;
  font-style: normal;
  color: black;

  @include mobile {
    margin-left: 0;
    font-size: $zr-font-size-xxxs;
  }
}

.signedVirtualStoreTeam {
 color: $dark-green1 ;
 font-size: $zr-font-size-xxxs;
 font-style: italic;
 font-family: $zr-font-family-digital-lighter;
}