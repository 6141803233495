// Colors
$dark-gray1: #202020;
$dark-gray2: #212121;
$light-green1: #1BB1A9;
$light-green2: #22B1A9;
$light-green3: #00a5a3;
$light-green4: #E6F2EA;
$light-green5: #00C2B7;
$dark-green1: #17616F;
$dark-green2: #276760;
$light-blue1: #199FBB;
$light-blue2: #66CCFF;
$dark-blue1: #0070AB;
$light-gray1: #CCCCCC;
$light-gray2: #ECECEC;
$light-gray3: #DDDDDD;
$light-gray4: #767676;
$light-gray5: #999999;
$light-gray6: #F7F7F7;
$light-gray7: #F0F0F0;
$light-gray8: #707070;
$light-gray9: #F5F5F5;

// Break Points
$breakpoint-mobile: 767px;
$breakpoint-tablet: 1023px;

// Sizes
$header-height: 3.625rem;
$side-menu-width: 13.5rem;
$side-menu-width-compact: 4.625rem;

// Timing
$menu-compact-trasition-timing: 0.5s;

$ff-default: "UOLText", Arial, sans-serif;
$ff-light: "UOLTextLight", Arial, sans-serif;
$ff-bold: "UOLTextBold", Arial, sans-serif;