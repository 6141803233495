@import '/src/App/Global/Style/_imports.scss';

.container {
  padding: $zr-spacing-horizontal-xxs 1.5rem 0 1.5rem;
  color: $light-gray4;

  a {
    text-decoration: none;
    color: #0070AB;
  }

  @include CQ {
    display: none;
  }

  @include mobile {
    display: none;
  }
}