@import '/src/App/Global/Style/_imports.scss';

.txtBuscaMenu {
  color: black;
  width: 0;
  opacity: 0;
  height: 35px;
  border-radius: 11px;
  padding-left: 30px;
  transition: width .3s;
  border: 1px solid #e1e5e6;
  font-size: 13px;
  outline: none;
}

.list {
  top: calc(100% + $zr-spacing-inset-micro);
  color: black;
  display: none;
  width: min(100vw, 500px);
  max-height: min(100vh, 458px);
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-padding-top: 8px;
  background-color: white;
  position: absolute;
  @include mobile {
    position: fixed;
    left: 0;
    width: 90vw;
    margin-left: 5vw;
  }
  border-radius: 6px;
  flex-direction: column;
  gap: $zr-spacing-inset-micro;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
  padding: $zr-spacing-inset-sm;
  .element {
    scroll-snap-align: start;
    border-radius: 6px;
    display: block;
    width: 100%;
    padding: $zr-spacing-inset-sm;
    transition: background-color .3s;
    &:hover {
      background-color: #f5f5f5;
    }
  }
}

hr {
  width: 100%;
  border-bottom: 1px solid #eee;
  &:last-child {
    display: none;
  }
}

.category {
    color: #194b61;
    display: block;
    font-size: 13px;
    font-weight: 700;
    padding: 0 5px;
    text-transform: uppercase;
}

.name {
  color: #333;
    display: block;
    font-size: 15px;
    font-weight: 700;
    padding: 5px;
}

.search {
  position: relative;
  height: 100%;
  width: 160px;

  &:focus-within .list, &:focus .list, &:hover .list {
    display: flex;
  }

  &.hidden {
    @include mobile {
      display: none;
    }
  }
}

.search:focus > .txtBuscaMenu, .search:focus-within > .txtBuscaMenu, .search:hover > .txtBuscaMenu {
  width: 159px;
  opacity: 1;
}

.search:focus > .txtBuscaMenu:not(:placeholder-shown) ~ #listagemPesquisaModulosTopo:has(div, p)  , .search:focus-within > .txtBuscaMenu:not(:placeholder-shown) ~ #listagemPesquisaModulosTopo:has(div, p) , .search:hover  > .txtBuscaMenu:not(:placeholder-shown) ~ #listagemPesquisaModulosTopo:has(div, p)   {
  display: block;
}

.magnifier {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 5px;
  margin: auto;
  width: 20px;
  filter: brightness(0) saturate(100%) invert(90%) sepia(4%) saturate(14%) hue-rotate(322deg) brightness(92%) contrast(88%);
  position: absolute;
}