@import '/src/App/Global/Style/_imports.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;

  &.open {
    opacity: 1;
    visibility: visible;

    :global(~main) {
      max-height: calc(100vh - $header-height);
    }

    :global(~main:last-of-type) {
      display: none;
    }

    .drawer-content {
      animation: bounceX .4s 1s both;
      animation-delay: .3s;
      animation-timing-function: ease-in-out;

      &:nth-of-type(n+3) { animation-delay: 0s; }

      @include mobile {
        animation: bounceY .4s 1s both;
        animation-delay: .3s;
        animation-timing-function: ease-in-out;
        // animation: none;
        // transform: translateY(0);
      }

    }// .drawer-content

  }// &.open

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(102, 102, 102, 0.9);
    backdrop-filter: blur(5px);
    transition: all 0.5ms ease-in-out 0s;
    // cursor: pointer;
  }// .backdrop

  .drawer-content {
    // position: relative;
    position: fixed;
    width: 100%;
    height: 100vh;
    max-width: 780px;
    display: flex;
    flex-direction: column;
    background: #fff;
    // padding: $zr-spacing-vertical-xs $zr-spacing-horizontal-xs;
    transform: translateX(100%);
    transition: transform 1s;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 200px;
      height: 100vh;
      background: #fff;
    }// &::after

    .btn-close {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 8px;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;
      transition: transform .3s;

      &:hover {
        transform: scale(1.2);
      }// &:hover

    }// .btn-close

    @include mobile {
      // padding: $zr-spacing-vertical-xs $zr-spacing-horizontal-xxs;
      transform: translateY(100%);
      transition: transform .5s;

      &::after {
        top: initial;
        bottom: 100%;
        left: 0;
        width: 100vw;
        height: 100px;
      }// &::after
    }

  }// .drawer-content

}// .container

@keyframes bounceX {
  0% { transform: translateX(110%) }
  70% { transform: translateX(-10%) }
  90% { transform: translateX(5%) }
  100% { transform: translateX(0) }
}

@keyframes bounceY {
  0% { transform: translateY(110%) }
  // 70% { transform: translateY(-10%) }
  // 90% { transform: translateY(5%) }
  100% { transform: translateY(0) }
}

@keyframes placeholderAnimation {
  from { transform: translate3d(-30%, 0, 0); }
  to { transform: translate3d(30%, 0, 0); }
}