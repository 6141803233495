@import '/src/App/Global/Style/_imports.scss';


.header {
  position: fixed;
  width: 100%;
  height: $header-height;
  background-color: white;
  box-shadow: 0px 0px 3px #0000004d;
  display: flex;
  align-items: center;
  z-index: 1;

  @include mobile {
    z-index: 9;
  }

  top: 0;
  left: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition: padding .5s;

  @include mobile {
    transition: none;
  }

  @media screen and (min-width: $breakpoint-mobile) {
    padding-left: 216px;
  }
}

.context-menu-container {
  display: flex;
  position: relative;
  align-items: center;
  gap: 25px;
  height: 50px;
}

.mobile {
  display: none;
  transition: all .5s;

  button {
    background-color: transparent;
    cursor: pointer;
    padding: 12px $zr-spacing-horizontal-xs;

    img {
      filter: brightness(0) saturate(100%) invert(29%) sepia(40%) saturate(835%) hue-rotate(142deg) brightness(95%) contrast(89%);
    }
  }

  @include mobile {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;

    img {
      height: 30px;
    }
  }
}

.divisor {
  border-right: 2px solid #8d8d8d44;
  height: 35px;
  margin-left: 5px;

  &.hidden {
    @include mobile {
      display: none;
    }
  }
}

:global(body:has(#side-menu.compact)) .header {
  padding-left: 70px;

  @include mobile {
    padding-left: 0;
  }
}

.img-container {
  width: $side-menu-width;
  display: flex;
  justify-content: center;
  border-right: 1px solid #ececec44;
  height: 35px;
}

.img-container>img {
  height: 40px;
  filter: brightness(0) invert(1);
}

.header-content {
  color: white;
  flex-grow: 1;
  padding-left: $zr-spacing-horizontal-xs;

  @include mobile {
    padding-left: $zr-spacing-horizontal-xxxs;
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 25px;
  padding-right: 35px;

  @include mobile {
    padding-right: 15px;
  }
}

.header-content a {
  text-decoration: none;
}

.search-container {
  flex-grow: 1;
  height: 35px;
}

.custom-left-header-item-container {
  display: none;
  @include mobile {
    display: block;
  }
}


.custom-right-header-item-container {
  display: none;
  @include mobile {
    display: block;
  }
}

.mobile-hidden {
  @include mobile {
    display: none;
  }
}