@import '/src/App/Global/Style/_imports.scss';

.container {
  width: 100%;
  background-color: lightgreen;

  @include mobile {
    // mobile
    background-color: lightcoral;
  }

  @media screen and (min-width:768px) and (max-width:$breakpoint-tablet) {
    // tablet
    background-color: lightyellow;
  }
}

.headerStyle {
  display: flex;
  justify-content: center;
}


.headerStyle img {
  width: 7.5rem;
  height: 3rem;
  margin-bottom: 3rem;
}

.welcome {
  display: flex;
  margin-bottom: 2rem;
}

.welcomeContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 2rem;
}

.welcomeContent p {
  font-family: UOLDisplay;
  font-size: 2.6rem;
  font-weight: bold;
  color:#00A5A3;
}

.welcomeContent span {
  color: #17616F;
  font-family: UOLtext;
  font-weight: bold;
  font-size: 17px;
}

.welcome img {
  width: 14rem;
  height: 10rem;
}

.content {
  display: block;
}

.contentLoja {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 1rem;
}

.contentLoja span::before {
  content: "Estamos aqui para ajudá-lo em sua jornada, e em apenas 3 passos \A você começar a vender. Desejamos muito sucesso em sua jornada empreendedora.";
  white-space: pre-wrap;
  
}

.contentLoja p {
  font-family: UOLtext, regular;
  line-height: 1.2;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  color:#17616F
}

.contentLoja span {
  line-height: 1.8;
  font-size: 1.1rem;
}


footer p {
  margin-left: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-family: UOLtext;
  font-style: italic;
  font-size: 1.1rem;
  color:#17616F;
}

@include mobile {
  .headerStyle img {
    height: 3rem;
    margin-bottom: 3rem;
  }

  .welcome {
    display: flex;
    margin-bottom: 2rem;
  }

  .welcome img {
    width: 10rem;
    margin-top: -2rem;
    height: 10rem;
  }

  .welcomeContent p {
    font-family: UOLDisplay;
    font-size: 1.0rem;
    font-weight: bold;
    color:#00A5A3;
    }

    .welcomeContent span {
      color: #17616F;
      font-family: UOLtext;
      font-weight: bold;
      font-size: 13px;
    }

    .contentLoja p {
      font-family: UOLtext, regular;
      line-height: 1.2;
      font-size: 1.0rem;
      margin-bottom: 2rem;
      color:#17616F
    }

    .contentLoja span {
      line-height: 1.5;
      font-size: 0.875rem;
    }

    .welcomeContent {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      margin-left: 2rem;
    }


    .contentLoja span::before {
      content: "Estamos aqui para ajudá-lo em sua \A jornada, e montamos 3 passos para você. \A começar a vender. \A Desejamos muito sucesso em sua jornada empreendedora.";
      white-space: pre-wrap;
      
    }
   
}