@import '/src/App/Global/Style/_imports.scss';

.container {
  width: 100%;
  @include dynamic-viewheight(100);
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  h1 {
    font-size: 10rem;
    color: $light-gray4;
    font-weight: bold;
  }
  h2 {
    font-size: 4rem;
    color: $light-gray4;
    padding-bottom: 40px;

    @media screen and (max-width: 800px) {
    font-size: 3rem;
    }
  }
}

img[alt="astronaut"] {
  width: 100%;
  min-width: 1000px;
  max-width: 1500px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: none;
  @media screen and (min-width: 700px) {
    display: block;
  }
}

img[alt="moon"] {
  position: absolute;
  top: 20px;
  right: 20px;
}