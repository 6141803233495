@import '/src/App/Global/Style/_imports.scss';

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 6px -3px;
}

:global(#side-menu) {
  width: $side-menu-width;
  @include dynamic-viewheight(100);
  background-color: white;
  box-shadow: 0px 3px 6px #0000004D;
  transition: width $menu-compact-trasition-timing;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 6;

  @include mobile {
    top: $header-height;
    height: calc(100vh - $header-height);
    height: --webkit-fill-available;
    height: calc(100dvh - $header-height);
  }

  .img-container {
    img {
      height: 40px;
    }

    @include mobile {
      display: none;
    }
  }

  a {
    color: $zr-color-neutral-medium-01;
  }
}

:global(#side-menu) * {
  font-size: 14px;
  font-family: $ff-light;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  user-select: none;
}

:global(#side-menu.compact) {
  width: 74px;

  @include mobile {
    width: 0;
  }

  .img-container img {
    transform: scale(0.7);
  }

  .goto-store-container a p {
    display: none !important;
    margin-bottom: 0 !important;
  }

  .loader-container p {
    display: none !important;
    margin-bottom: 0 !important;
  }
}

.loading {
  height: 56px;
  opacity: .3;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
}

.nav-items {
  overflow-y: auto;
  height: calc(100% - 170px);

  @include mobile {
    height: calc(100% - $header-height);
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #C6C6C6;
    border-radius: 10px;
    border: 0 solid #ffffff;
  }
}

.goto-store-container {
  background: linear-gradient(#00B4AA, #00B4AA);
  transition: background-size .3s;
  background-size: 0% 100%;
  background-repeat: no-repeat;
  width: 100%;
  overflow: hidden;
  height: 56px;
  display: flex;
  align-items: center;
  padding-left: 26px;
  cursor: pointer;

  &:hover {
    background-size: 100% 100%;

    p {
      color: white !important;
    }

    img {
      filter: brightness(0)invert(100%);
    }
  }

  a {
    color: $light-green3;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    display: flex;
    gap: 20px;

    p {
      color: #00B4AA;
      // font-family: UOLDisplay !important;
      // font-weight: bold !important;
      font-family: $ff-bold !important;
    }
  }

  img,
  svg {
    filter: brightness(0) saturate(100%) invert(44%) sepia(44%) saturate(6757%) hue-rotate(156deg) brightness(102%) contrast(101%);
    width: 20px;
  }
}


.img-container {
  height: 58px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.img-container a {
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-nav {
  height: $header-height;
  width: inherit;
  box-shadow: 0px 0px 3px #0000004D;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 27px;
  background-color: white;
  cursor: pointer;

  @include mobile {
    display: none;
  }
}

:global(#side-menu.compact).close-nav {
  @media screen and (min-width: 768px) {
    padding: 27px 30px;
  }
}

.close-nav img {
  transform: rotate(180deg);
  height: 20px;
  transition: transform 0.5s;
}

:global(#side-menu.compact) .close-nav img {
  transform: rotate(0deg);
}

:global(#side-menu)-items {
  overflow-y: auto;
  height: calc(100% - 56px);
}

:global(#side-menu)-items::-webkit-scrollbar {
  width: 5px;
}

:global(#side-menu)-items::-webkit-scrollbar-track {
  background: #ffffff;
}

:global(#side-menu)-items::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 0 solid #ffffff;
}

.back {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: $header-height;
  left: 0;
  background-color: rgba(133, 133, 133, 0.466);
  z-index: 5;

  @include mobile {
    display: block;
  }
}

.loader-container {
  width: 100%;
  display: flex;
  align-items: center;
  color: #00B4AA !important;
  gap: 20px;
  overflow: hidden;
  padding-left: 26px;
  height: 56px;

  p {
    color: #00B4AA;
    // font-family: UOLDisplay !important;
    // font-weight: bold !important;
    font-family: $ff-bold !important;
  }

  img,
  svg {
    width: 20px;
    filter: brightness(0) saturate(100%) invert(44%) sepia(44%) saturate(6757%) hue-rotate(156deg) brightness(102%) contrast(101%);
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg)
    }
  }

}