@import '/src/App/Global/Style/_imports.scss';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;

  &.open {
    opacity: 1;
    visibility: visible;

    :global(~main) {
      max-height: calc(100vh - $header-height);
    }

    :global(~main:last-of-type) {
      display: none;
    }

    .side-content {
      animation: bounceX .4s 1s both;
      animation-delay: .3s;
      animation-timing-function: ease-in-out;

      @include mobile {
        animation: bounceY .4s 1s both;
        animation-delay: .3s;
        animation-timing-function: ease-in-out;
        // animation: none;
        // transform: translateY(0);
      }

    }// .side-content

  }// &.open

  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(102, 102, 102, 0.9);
    backdrop-filter: blur(5px);
    transition: all 0.5ms ease-in-out 0s;
    cursor: pointer;
  }// .backdrop

  .side-content {
    position: relative;
    width: 100%;
    max-width: 780px;
    background: #fff;
    padding: $zr-spacing-vertical-xs $zr-spacing-horizontal-xs;
    transform: translateX(100%);
    transition: transform 1s;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 100%;
      width: 200px;
      height: 100vh;
      background: #fff;
    }// &::after

    .content-title {
      color: $light-gray5;
      font-size: $zr-font-size-micro;
    }// .content-title

    .content-header {
      margin-bottom: $zr-spacing-vertical-xs;
      padding-top: $zr-spacing-vertical-md;

      .header-title {
        color: $dark-green1;
        font-size: $zr-font-size-sm;
        margin-bottom: $zr-spacing-vertical-xxs;
      }// .header-title

      &>div {
        width: 100%;
        max-width: 450px;
      }// &>div

    }// .content-header

    .content-body {

      .table-wrap {
        overflow-x: scroll;
        &::-webkit-scrollbar-track {background-color: #fff;}

        .resp-table {
          width: 100%;
          display: table;
          border-collapse: separate;
          border-spacing: 0 15px;

          .resp-table-header {
            display: table-header-group;
            background-color: $light-gray1;
            color: $dark-gray1;
            font-size: $zr-font-size-xs;
            text-shadow: 0px 0px 0.8px $dark-gray1;

            .table-header-cell {
              display: table-cell;
              padding: $zr-spacing-vertical-xxxs $zr-spacing-horizontal-xxxs;
              text-align: justify;
            }

            @include mobile {
              font-size: $zr-font-size-micro;
            }

          }// .resp-table-header

          .resp-table-body {
            display: table-row-group;

            .resp-table-row {
              display: table-row;
              background-color: $light-gray2;
              color: $dark-gray1;
              font-size: $zr-font-size-xs;
              text-shadow: 0px 0px 0.8px $dark-gray1;
              text-transform: capitalize;

              .table-body-cell {
                display: table-cell;
                padding: $zr-spacing-vertical-xxxs $zr-spacing-horizontal-xxxs;

                span {
                  display: block;
                  min-height: 24px;
                }

              }

              @include mobile {
                font-size: $zr-font-size-xxxs;
              }

            }// .resp-table-row

          }// .resp-table-body

        }// .resp-table

        &.is-loading {

          .resp-table .resp-table-body .table-body-cell span {
            position: relative;
            // display: blo;
            flex-wrap: wrap;
            background-color: #ebebeb;
            border-radius: 4px;
            overflow: hidden;
            user-select: none;
            cursor: wait;

            color: transparent;
            text-shadow: none;

            &:before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 50%;
              z-index: 1;
              width: 400%;
              margin-left: -250%;
              animation: placeholderAnimation 1.2s linear infinite;
              background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, .35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%
            }// &:before
          }

        }// &.is-loading

      }// .table-wrap

    }// .content-body

    .btn-close {
      position: absolute;
      top: 8px;
      right: 8px;
      padding: 8px;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;
      transition: transform .3s;

      &:hover {
        transform: scale(1.2);
      }// &:hover

    }// .btn-close

    @include mobile {
      padding: $zr-spacing-vertical-xs $zr-spacing-horizontal-xxs;
      transform: translateY(100%);
      transition: transform .5s;

      &::after {
        top: initial;
        bottom: 100%;
        left: 0;
        width: 100vw;
        height: 100px;
      }// &::after
    }

  }// .side-content

}// .container

@keyframes bounceX {
  0% { transform: translateX(110%) }
  70% { transform: translateX(-10%) }
  90% { transform: translateX(5%) }
  100% { transform: translateX(0) }
}

@keyframes bounceY {
  0% { transform: translateY(110%) }
  // 70% { transform: translateY(-10%) }
  // 90% { transform: translateY(5%) }
  100% { transform: translateY(0) }
}

@keyframes placeholderAnimation {
  from { transform: translate3d(-30%, 0, 0); }
  to { transform: translate3d(30%, 0, 0); }
}