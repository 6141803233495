@import '/src/App/Global/Style/_imports.scss';

.container {
  display: flex;
  gap: $zr-spacing-horizontal-xxs;
}

.context-controller {
  height: 69px;
  width: inherit;
  display: flex;
  label {
    input {
      display: none;
    }

    &:has(input[type="radio"]:checked) {
      border-bottom: 3px solid $dark-green1;
      color: $dark-green1;
    }
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-bottom: 3px solid $light-gray1;
    color: $light-gray1;
    font-size: $zr-font-size-xxs;
    cursor: pointer;

    &.selected {
      border-bottom: 3px solid $dark-green1;
      color: $dark-green1;
    }
  }
}

.arrow-animation-wrapper {
  cursor: pointer;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -12px 0;
  position: relative;
}

.arrow-animation-wrapper::after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #17616F;
  border-right: 1px solid #17616F;
  width: 3px;
  height: 3px;
  bottom: 20px;
  left: 100%;
  transform: rotate(45deg) translate(-50%, 0);
  transition: all .3s;

  @include mobile {
    bottom: 21px;
  }

}

.arrow-animation-wrapper:focus::after, .arrow-animation-wrapper:focus-within::after {
  width: 8px;
  height: 8px;
  background-color: #f9f9f9;
  transform: translate(-50%, 0) rotate(225deg);;
  bottom: 3px;
  left: 50%;

  @include mobile {
    bottom: 3px;
  }

}

.divisor {
  border-right: 2px solid #8d8d8d44;
  height: 35px;
  margin-left: 5px;
}

.mono {
  display: flex;
}

.dynamic {
  display: none;
}

@include mobile {
  .mono {
    display: none;
  }
  .dynamic {
    display: flex;
  }

  .divisor {
    display: none;
  }
 }

.header-nav {

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background .3s;

    color: $zr-color-neutral-medium-01;
    font-size: $zr-font-size-nano;
    font-family: $ff-light;

    &:hover {
      background: rgb(236, 236, 236);

      :global(#Caminho_65519) {animation-play-state: running;}
      :global(#Caminho_65521) {animation-play-state: running;}

    }

    :global(#Caminho_65519) {
      animation-name: spin;
      animation-duration: .9s;
      // animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      transform-origin: 18.735px 11.02px;
      // transform-origin: 14px 6px;
      // transform-origin: 9.47px 10.02px;
      // transform-origin: 4,735px 5.01px;
      animation-play-state: paused;
    }

    :global(#Caminho_65521) {
      animation-name: spin;
      animation-duration: 1s;
      // animation-duration: 3000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-direction: reverse;
      transform-origin: 12.48px 3.67px;
      // transform-origin: 9px 0;
      // transform-origin: 6.96px 7.34px;
      // transform-origin: 3.48px 3.67px;
      animation-play-state: paused;
    }

    :global(#icon-atendimento) {
      width: 20px;
      height: 21px;
    }

  }// a

  @include mobile {
    display: none;
  }

}

@keyframes spin {
  from { rotate: 0deg }
  to { rotate: 360deg }
}